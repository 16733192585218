import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './ContactModal.css';

const ContactModal = ({ closeContactModal, productName, productColor, woodType, link }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: `Dzień dobry,\n\nJestem zainteresowany/a dostępnością mebla: ${productName}. Poniżej szczegóły:\n\n` +
                 `Link do produktu: ${link}\nKolor: ${productColor}\nRodzaj drewna: ${woodType}\n\n` +
                 `Proszę o informację dotyczącą dostępności oraz przewidywanego czasu realizacji zamówienia\n\n` +
                 `Informacje dodatkowe:\n`
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        $.ajax({
            type: 'POST',
            url: '/php/send-email.php', // Ścieżka do pliku PHP
            data: formData,
            success: function (response) {
                // Czyszczenie formularza po wysłaniu
                setFormData({
                    name: '',
                    email: '',
                    message: `Dzień dobry,\n\nJestem zainteresowany/a dostępnością mebla: ${productName}. Poniżej szczegóły:\n\n` +
                             `Link do produktu: ${link}\nKolor: ${productColor}\nRodzaj drewna: ${woodType}\n\n` +
                             `Proszę o informację dotyczącą dostępności oraz przewidywanego czasu realizacji zamówienia\n\n` +
                             `Informacje dodatkowe:\n`
                });
                console.log(response); // Odpowiedź z PHP
                alert('Wiadomość została wysłana pomyślnie!');
                
            },
            error: function () {
                alert('Wystąpił problem podczas wysyłania wiadomości. Spróbuj ponownie.');
            }
        });
    };

   
    useEffect(() => {
        setTimeout(() => {
            $('#contact-modal').addClass('visible');
            document.body.classList.add('modal-open'); // Disable scrolling on the background
        }, 100);

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeContactModal();
            }
        }
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup: remove event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            document.body.classList.remove('modal-open'); // Restore scrolling on the background
        };
    }, [closeContactModal]);

    return (
        <div id="contact-modal">
            <div id='closeBtnContactModal' >
                        {/* <img className="rectangle-x" onClick={closeContactModal} src={`${process.env.PUBLIC_URL}/images/rectangle-x.svg`} alt="Close" /> */}
                        <svg id="closeButtonContactSVG" onClick={closeContactModal} data-name="button on " xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33.65 33.962">
                            <g id="Rectangle_38" data-name="Rectangle 38" fill="none" stroke="#a9b1aa" stroke-width="1">
                                <rect width="33.65" height="33.962" rx="16.825" stroke="none"/>
                                <rect x="0.5" y="0.5" width="32.65" height="32.962" rx="16.325" fill="#a9b1aa"/>
                            </g>
                            <line id="Line_32" data-name="Line 32" x2="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#eaeaeb" stroke-width="1"/>
                            <line id="Line_33" data-name="Line 33" x1="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#eaeaeb" stroke-width="1"/>
                        </svg>
            </div>
            
            <h1>kontakt</h1>
            <p>Chcesz się skontaktować z nami? Nie wahaj się i napisz lub zadzwoń! Jesteśmy dostępni, aby odpowiedzieć na Twoje pytania, rozwiać wątpliwości lub omówić szczegóły projektu.</p>

            <div className="contact-info-email-modal">
                <img src={`${process.env.PUBLIC_URL}/images/tel.svg`} alt="email"></img>
                <span>+48 123 456 789</span>
            </div>
                    
            <div className="contact-info-tel-modal">
                <img src={`${process.env.PUBLIC_URL}/images/mail.svg`} alt="email"></img>
                <span>info@olmomeble.pl</span>
            </div>

            <p id="zapytanie">wyślij swoje zapytanie</p>

            <form id='contact-form' onSubmit={handleSubmit}>
                    <div id='form-row'>
                        <input
                            id='form-name'
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='IMIĘ I NAZWISKO'
                            required
                        />
                        <input
                            id="form-email"
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='EMAIL'
                            required
                        />
                    </div>
                    <textarea
                        type='text'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        placeholder='WIADOMOŚĆ'
                        required
                    ></textarea>
                    <button type="submit">WYŚLIJ</button>
                </form>
        </div>
    );
}

export default ContactModal;