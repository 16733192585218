import React, { useState } from "react";
import './Contact.css';
import $ from 'jquery';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        $.ajax({
            type: 'POST',
            url: '/php/send-email.php', // Ścieżka do pliku PHP
            data: formData,
            success: function (response) {
                // Czyszczenie formularza po wysłaniu
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
                console.log(response); // Odpowiedź z PHP
                alert('Wiadomość została wysłana pomyślnie!');
                
            },
            error: function () {
                alert('Wystąpił problem podczas wysyłania wiadomości. Spróbuj ponownie.');
            }
        });
    };


    return (
        <div id="contact">
            <h1>kontakt</h1>
            <p>Chcesz się skontaktować z nami? Nie wahaj się i napisz lub zadzwoń! Jesteśmy dostępni, aby odpowiedzieć na Twoje pytania, rozwiać wątpliwości lub omówić szczegóły projektu.</p>

            

            <p id="zapytanie">wyślij swoje zapytanie</p>

            <form id='contact-form' onSubmit={handleSubmit}>
                    <div id='form-row'>
                        <input
                            id='form-name'
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='IMIĘ I NAZWISKO'
                            required
                        />
                        <input
                            id="form-email"
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='EMAIL'
                            required
                        />
                    </div>
                    <textarea
                        type='text'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        placeholder='WIADOMOŚĆ'
                        required
                    ></textarea>
                    <div id="contact-bottom-row">
                        <div id="contact-info">
                            <div id="contact-info-email">
                                <img src={`${process.env.PUBLIC_URL}/images/mail.svg`} alt="email"></img>
                                <span>info@olmomeble.pl</span>
                            </div>

                            <div id="contact-info-tel">
                                <img src={`${process.env.PUBLIC_URL}/images/tel.svg`} alt="email"></img>
                                <span>+48 123 456 789</span>
                            </div>

                        </div>
                        <button type="submit">WYŚLIJ</button>
                    </div>
                    
                </form>
        </div>
    );
}

export default Contact;