import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactModal from '../ContactModal/ContactModal';
import $ from 'jquery';
import './FurnitureItem.css';


const FurnitureItem = ({ product }) => {

  
    console.log(product);


    const [showContactModal, setShowContactModal] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [isTransparent, setIsTransparent] = useState(false);
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const navigate = useNavigate(); // Initialize useNavigate

    const colors = ['biały', 'czarny'];

    useEffect(() => {
        // Set SVGs to become transparent 1 second after mounting
        const timer = setTimeout(() => {
            setIsTransparent(true);
        }, 1000);

        return () => clearTimeout(timer); // Clear timeout on unmount
    }, []);
    
    const openContactModal = () => {
        setShowContactModal(true);
    }

    const closeContactModal = () => {
        $('#contact-modal').removeClass('visible');

        setTimeout(() => {
            setShowContactModal(false);
        }, 600);
        document.body.classList.remove('modal-open');
    }

    const nextImage = () => {
        if (currentPhotoIndex < product.numOfPhotos - 1) {
            setCurrentPhotoIndex(currentPhotoIndex + 1);
        } else {
            setCurrentPhotoIndex(0);
        }
    };

    const previousImage = () => {
        if (currentPhotoIndex > 0) {
            setCurrentPhotoIndex(currentPhotoIndex - 1);
        } else {
            setCurrentPhotoIndex(product.numOfPhotos - 1);
        }
    }

    const handleNextColor = () => {
        setCurrentColorIndex((currentColorIndex + 1) % colors.length);
    };

    const handlePreviousColor = () => {
        setCurrentColorIndex((currentColorIndex - 1 + colors.length) % colors.length);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeContactModal();
                navigate('/produkty'); // Optionally navigate to /produkty
            }
        };



        // Add the keydown event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup: remove the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate]); // Add dependencies

    return (
        <div className="flex-container-product">
                <div id="product-info">
                    <div id='closeButton'>
                        <Link to='/produkty'>
                        <svg id="closeButtonProductSVG" data-name="button on " xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33.65 33.962">
                            <g id="Rectangle_38" data-name="Rectangle 38" fill="none" stroke="#a9b1aa" stroke-width="1">
                                <rect width="33.65" height="33.962" rx="16.825" stroke="none"/>
                                <rect x="0.5" y="0.5" width="32.65" height="32.962" rx="16.325" fill="#a9b1aa"/>
                            </g>
                            <line id="Line_32" data-name="Line 32" x2="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#eaeaeb" stroke-width="1"/>
                            <line id="Line_33" data-name="Line 33" x1="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#eaeaeb" stroke-width="1"/>
                        </svg>


                        </Link>
                    </div>
                    <div id='info-row-1'>
                        <h1 id="product-name">{product.name}</h1>
                        {product.description && <p id="desc" style={{ whiteSpace: 'pre-line' }}>{product.description}</p>}
                    </div>
                    <div id='info-row-2'>
                        <div id='info-col-1'>
                            
                            <p id="price">{product.price}</p>
                            
                            
                            <span id='materialy-span'><b>materiały</b></span>

                            <div id='materialy-container'>
                                <div id='drewno-container'>
                                    <span id='drewno-value'>dąb</span>
                                    
                                    <div className='arrows-container' id='drewno'>
                                        <svg id='arrow-left-thin' xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                                            <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                                        </svg>
                                        <div id='drewno-kolor' className='drewno1'></div>
                                        <svg id='arrow-right-thin' xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                                            <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                                        </svg>

                                    </div>
                                </div>

                                <div id='stal-container'>
                                    <span id='color-value'>{colors[currentColorIndex]}</span>
                                    <div className='arrows-container' id='metal'>
                                        <svg id='arrow-left-thin' xmlns="http://www.w3.org/2000/svg" onClick={handlePreviousColor} width="35" height="35" viewBox="0 0 37 43">
                                            <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                                        </svg>
                                        <div id='metal-kolor' className={colors[currentColorIndex]}></div>
                                        <svg id='arrow-right-thin' xmlns="http://www.w3.org/2000/svg" onClick={handleNextColor} width="35" height="35" viewBox="0 0 37 43">
                                            <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                                
                            <div className='arrows-container'>
                                
                            </div>

                            <div id='dimensions'>
                                {product.height && <p id='height'>Wysokość <span>{product.height}</span></p>}
                                {product.width && <p id='width'>Szerokość <span>{product.width}</span></p>}
                                {product.depth && <p id='depth'>Głębokość <span>{product.depth}</span></p>}
                            </div> 

                            <div id='button-container-1'>
                                <button onClick={openContactModal}>ZAPYTAJ</button>
                            </div>
                        </div>
                        
                        <div id='info-col-2'>
                            <span style={{fontWeight: 'bold', fontSize: '14px'}}>materiały</span>
                            <span id='drewno-value'>dąb</span>
                            <div className='arrows-container' id='drewno'>
                                <svg id='arrow-left-thin' xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                                    <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                                </svg>
                                <div id='drewno-kolor' className='drewno1'></div>
                                <svg id='arrow-right-thin' xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                                    <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                                </svg>

                            </div>

                            <span id='color-value'>{colors[currentColorIndex]}</span>
                            <div className='arrows-container' id='metal'>
                                <svg id='arrow-left-thin' xmlns="http://www.w3.org/2000/svg" onClick={handlePreviousColor} width="35" height="35" viewBox="0 0 37 43">
                                    <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                                </svg>
                                <div id='metal-kolor' className={colors[currentColorIndex]}></div>
                                <svg id='arrow-right-thin' xmlns="http://www.w3.org/2000/svg" onClick={handleNextColor} width="35" height="35" viewBox="0 0 37 43">
                                    <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                                </svg>
                            </div>

                        </div>
                    

                        
                    </div>
                    
                </div>
                

                <div id="product-photo-container">
                    <img id="product-photo" src={`${process.env.PUBLIC_URL}${product.galleryPath}/img${currentPhotoIndex + 1}.jpg`} alt={product.name} />
                    <svg className={`arrow-left ${isTransparent ? 'transparent' : ''}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                        <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                    </svg>

                    <svg className={`arrow-right ${isTransparent ? 'transparent' : ''}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                        <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                    </svg>

                    <div id='prev-img-btn' onClick={previousImage} alt='previous photo'></div>
                    <div id='next-img-btn' onClick={nextImage} alt='next photo'></div>
                    
                    
                </div>
                
                {showContactModal && (
                    <ContactModal
                    closeContactModal={closeContactModal}
                    productName={product.name}
                    woodType="dąb" // Stale, ale można zmienić, jeśli jest dynamiczne
                    productColor={colors[currentColorIndex]}
                    link={window.location.href}
                />)}
        </div>
    )
}; 

export default FurnitureItem;