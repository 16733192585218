import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Slider.css'; 

const Slider = () => {

    const [furnitureItems, setFurnitureItems] = useState([]);
    const [galleryOrder, setGalleryOrder] = useState([]);

    useEffect(() => {

        //fetch furniture items
        $.ajax({
            url: '/php/getAllFurniture.php',
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                setFurnitureItems(data);
            },
            error: function (xhr, status, error) {
                console.error('~Error fetching furniture items: ', error);
            }
        });

        //fetch gallery order
        $.ajax({
            url: '/php/getGalleryOrder.php',
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                setGalleryOrder(data);
            },
            error: function(xhr, status, error) {
                console.error('Error fetching gallery data ', error);
            }
        });

    }, []);


    //map gallery order to find matching furniture items

    const sliderItems = galleryOrder.map((order) => {
        const {item_id} = order;

        //skip null entries
        if (item_id == null) return null;

        //find matching items by item_id
        const item = furnitureItems.find(furniture => furniture.id === item_id);
        
        if (!item) return null;

        return (
            <div className="slide" key={item.id}>
                <Link to="/produkty">
                    <img src={`${process.env.PUBLIC_URL}${item.imageSrc}`} alt={item.name} />
                </Link>
            </div>
        );
    }).filter(Boolean);  //filter null entries from resulting array

    


    return (
        <div className="slider">
            <Link to='/produkty' className="nav-link-meble">nasze produkty</Link>
            <div className="slide-track">
                {/*return item matching furniture items*/}
                {sliderItems}
                {sliderItems}
            </div>
        </div>
    );
};

export default Slider;
